<template>
  <div>
    <div class="text-right mb-2">
      <base-button
        variant="btn-black"
        text="Clear All"
        :disabled="assetsLoading"
        @click="handleClearAll()"
      />
    </div>
    <assets-filter-card
      v-for="(filter, foIndex) in assetsFilters.filter(f => f?.options?.length)"
      :ref="(el) => { childFilterCards.push(el) }"
      :key="'filter-card' + (filter.id || foIndex)"
      :title="filter.name"
      :filter-data="filter"
      :model-value="true"
      @optionSelected="handleFilterUpdate($event)"
    />
    <div class="text-right">
      <base-button
        class="mt-2 mb-2"
        variant="btn-black"
        text="Clear All"
        :disabled="assetsLoading"
        @click="handleClearAll()"
      />
    </div>
  </div>
</template>

<script>
import { computed, defineAsyncComponent, ref, onBeforeUpdate } from 'vue';
import { useStore } from 'vuex';
import useStyleGuideAssets from '@/hooks/styleGuideAssets.js';

export default {
    name: 'AssetsFiltersList',

    components: {
        AssetsFilterCard: defineAsyncComponent(() => import('./AssetsFilterCard.vue')),
        BaseButton: defineAsyncComponent(() => import('@/components/generic-components/BaseButton.vue'))
    },

    setup () {
        const store = useStore();
        const assetsFilters = computed(() => store.getters['assets/getAssetsFilters']);

        const { fetchAssetsList, assetsList, searchParams, saveSearchParams, clearAllSearchFilters, assetsLoading } = useStyleGuideAssets();
        const handleFilterUpdate = async (filterData) => {
            store.dispatch('assets/setSearchPage', 1 )

            const params = {};
            params[filterData.queryParamKey] = filterData.selectedOptions;
            await saveSearchParams({ ...params }, true)
            await fetchAssetsList({ params: { ...searchParams.value }, retainFacets: true })
        };


        const childFilterCards = ref([])
        onBeforeUpdate(() => {
          childFilterCards.value = []
        })

        const handleClearAll = async () => {
            try {
                childFilterCards.value.forEach(card => card.clearAllSelections())
                await clearAllSearchFilters();
                store.dispatch('assets/setSearchPage', 1 )
                await fetchAssetsList({ params: { ...searchParams.value } })
            } catch (err) {
                console.error(err);
            }
        };

        return {
            assetsFilters,
            handleFilterUpdate,
            handleClearAll,
            assetsLoading,
            searchParams,
            assetsList,
            childFilterCards
        };
    }
};
</script>
